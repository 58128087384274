<template>
  <Toast />
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    :header="'CIERRE DE CAJA: #' + data_cajacierre.id"
    :modal="true"
    @hide="ocultarModal"
    class="p-fluid"
  >
    <div class="col-12">
      <Divider align="left">
        <div class="inline-flex align-items-center">
          <i class="pi pi-unlock"></i>
          <b>DATOS DE APERTURA</b>
        </div>
      </Divider>
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid p-fondo">
          <div class="field col-12 md:col-6">
            <label for=""
              ><strong>USUARIO : </strong
              >{{ data_cajacierre.nombre_user }}</label
            >
          </div>
          <div class="field col-12 md:col-6">
            <label for=""
              ><strong>SUCURSAL : </strong
              >{{ data_cajacierre.nombre_sucursal }}</label
            >
          </div>
          <div class="field col-12 md:col-6">
            <strong> MONTO INICIAL : </strong
            >{{ convertirNumeroGermanicFormat(data_cajacierre.saldo_inicial) }}
            Bs
          </div>
          <div class="field col-12 md:col-6">
            <label for=""
              ><strong>FECHA APERTURA:</strong>
              {{ data_cajacierre.fecha_apertura }}</label
            >
          </div>
        </div>
      </div>
      <Divider align="left" v-if="'Caja Ver Detalle' in auth.user.permissions">
        <div class="inline-flex align-items-center">
          <i class="pi pi-lock"></i>
          <b>DATOS DE CIERRE</b>
        </div>
      </Divider>
      <div
        class="card p-fondo2"
        v-if="'Caja Ver Detalle' in auth.user.permissions"
      >
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <strong> TOTAL VENTAS : </strong
            >{{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_ventas
              )
            }}
          </div>
          <div class="field col-12 md:col-4">
            <strong>TOTAL EFECTIVO BS. : </strong
            >{{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_ventas_efectivo
              )
            }}
          </div>
          <!-- <div class="field col-12 md:col-4">
						<strong>TRANSFERENCIAS : </strong
						>{{
							convertirNumeroGermanicFormat(
								data_cajacierre.calculo_total_ventas_metodos_pago
							)
						}}
					</div> -->
          <div
            class="field col-12 md:col-4"
            v-if="data_cajacierre.detalle_suma_cobros_x_metodos_pago.length > 0"
          >
            <div
              v-for="(
                item, index
              ) in data_cajacierre.detalle_suma_cobros_x_metodos_pago"
              :key="index"
            >
              <strong>{{ item.nombre }} : </strong
              >{{ convertirNumeroGermanicFormat(item.total) }}
            </div>
          </div>
          <div class="field col-12 md:col-4">
            <strong> TOTAL INGRESOS BS. : </strong
            >{{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_ingresos
              )
            }}
          </div>
          <div class="field col-12 md:col-4">
            <strong> TOTAL EGRESOS BS. : </strong
            >{{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_egresos
              )
            }}
          </div>
          <!-- <div class="field col-12 md:col-4">
            <strong>VENTAS AL CR&Eacute;DITO: </strong
            >{{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_a_credito
              )
            }}
          </div> -->
          <div class="field col-12 md:col-4">
            <strong> DIFERENCIA : </strong
            >{{ convertirNumeroGermanicFormat(diferencia) }}
          </div>
          <div class="field col-12 md:col-4">
            <strong> SALDO CAJA EN SISTEMA: </strong
            >{{
              convertirNumeroGermanicFormat(
                data_cajacierre.calculo_total_saldo_caja
              )
            }}
          </div>
          <div class="field col-12 md:col-4">
            <strong> SALDO EN CAJA BS. : </strong
            >{{ convertirNumeroGermanicFormat(saldo_caja) }}
          </div>
        </div>
      </div>
      <Divider align="left">
        <div class="inline-flex align-items-center">
          <i class="pi pi-cog"></i>
          <b>OPERACIONES</b>
        </div>
      </Divider>
      <div class="p-fluid formgrid grid">
        <table class="field col-12 md:col-12">
          <thead>
            <tr v-show="false">
              <td><strong>FECHA DE CIERRE:</strong></td>
              <td>
                <Calendar
                  v-model="data_cajacierre.fecha_cierre"
                  :showIcon="true"
                  dateFormat="dd/mm/yy"
                  disabled
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong
                  >RECUENTO EFECTIVO: <small class="p-invalid">*</small></strong
                >
              </td>
              <td>
                <InputNumber
                  id="recuento_efectivo"
                  inputClass="text-right"
                  :min="0"
                  :showButtons="true"
                  autofocus
                  v-model="data_cajacierre.recuento_efectivo"
                  :minFractionDigits="2"
                  locale="de-DE"
                  placeholder="Ingrese el Monto total en efectivo"
                  :class="{ 'p-invalid': errors.recuento_efectivo }"
                />
                <small class="p-invalid" v-if="errors.recuento_efectivo">{{
                  errors.recuento_efectivo[0]
                }}</small>
              </td>
            </tr>
            <tr v-if="data_cajacierre.tipo_negocio == 2">
              <td>
                <strong
                  >LECTURA FINAL: <small class="p-invalid">*</small></strong
                >
              </td>
              <td>
                <InputNumber
                  id="lectura_fin"
                  inputClass="text-right"
                  :min="0"
                  :showButtons="true"
                  v-model="data_cajacierre.lectura_fin"
                  :minFractionDigits="2"
                  locale="de-DE"
                  placeholder="Ingrese el Lectura final del Medidor"
                  :class="{ 'p-invalid': errors.lectura_fin }"
                />
                <small class="p-invalid" v-if="errors.lectura_fin">{{
                  errors.lectura_fin[0]
                }}</small>
              </td>
            </tr>
            <!-- <tr>
              <td><strong>MONTO A ENTREGAR:</strong></td>
              <td>
                <InputNumber
                  v-model="data_cajacierre.monto_entregar"
                  :minFractionDigits="2"
                  :max="saldo_caja"
                  :showButtons="true"
                  model="decimal"
                  locale="de-DE"
                  inputClass="text-right"
                />
              </td>
            </tr> -->
            <tr>
              <td>
                <strong>OBSERVACIÓN: </strong>
              </td>
              <td>
                <Textarea
                  v-model="data_cajacierre.observacion"
                  rows="1"
                  autoResize
                />
                <small class="p-invalid" v-if="errors.observacion">{{
                  errors.observacion[0]
                }}</small>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarModal"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="GUARDAR"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="guardarCajaCierre"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>
<script>
import CajaService from "@/service/CajaService";
import { useAuth } from "@/stores";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cajacierre: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      errors: {},
      enviado: false,
      mostrarModal: this.show,
      data_cajacierre: this.cajacierre,
    };
  },
  cajacierreService: null,
  auth: null,
  computed: {
    saldo_caja() {
      return parseFloat(this.data_cajacierre.recuento_efectivo);
    },
    diferencia() {
      return (
        parseFloat(this.data_cajacierre.recuento_efectivo) -
        parseFloat(this.data_cajacierre.calculo_total_saldo_caja)
      );
    },
  },
  created() {
    this.cajacierreService = new CajaService();
    this.auth = useAuth();
  },
  methods: {
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    ocultarModal() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
    },
    validacionesForm() {
      if (
        this.data_cajacierre.tipo_negocio == 2 &&
        !this.data_cajacierre.lectura_fin
      ) {
        this.errors.lectura_fin = [
          "DEBE INGRESAR LA LECTURA FINAL DEL MEDIDOR",
        ];
      }
      return Object.keys(this.errors).length === 0;
    },
    guardarCajaCierre() {
      this.errors = {};
      if (!this.validacionesForm()) {
        return;
      }
      this.enviado = true;
      let tmp = {
        ...this.data_cajacierre,
        estado: this.data_cajacierre.estado.value,
        total_ventas: this.data_cajacierre.calculo_total_ventas,
        diferencia: this.diferencia,
        saldo_caja: this.saldo_caja,
        recuento_metodos_pago:
          this.data_cajacierre.detalle_suma_cobros_x_metodos_pago,
        lectura_fin: this.data_cajacierre.lectura_fin,
      };

      this.cajacierreService.updatedCaja(tmp).then((data) => {
        if (Object.prototype.hasOwnProperty.call(data, "errors")) {
          this.errors = data.errors;
        } else {
          this.$toast.add({
            severity: "success",
            summary: "Exito!",
            detail: data.mensaje,
            life: 3000,
          });
          this.$emit("actualizarListado");
          this.ocultarModal();
        }
        this.enviado = false;
      });
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    cajacierre(val) {
      val.diferencia = 0;
      val.recuento_efectivo = null;
      val.monto_entregar = 0;
      val.fecha_cierre = new Date();
      val.lectura_fin = null;
      this.data_cajacierre = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
  font-weight: bold;
}
.p-fondo {
  background-color: #d8fadb;
}
.p-fondo2 {
  background-color: #f8d7da;
}
</style>
