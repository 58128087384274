import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class CajaService {
  async getCajasAll() {
    const cajas = await fetchWrapper.get(`${ruta}/caja`);
    return cajas;
  }

  async registrarAperturaCaja(caja) {
   // console.log(caja);
    const nuevaCaja = await fetchWrapper.post(`${ruta}/caja`, caja);
    return nuevaCaja;
  }
  async updatedCaja(caja) {
  
    const cajaActualizada = await fetchWrapper.put(
      `${ruta}/caja/` + caja.id,
      caja
    );
    return cajaActualizada;
  }
  async imprimirCaja(id) {
    return await fetchWrapper.getPdf(`${ruta}/caja/imprimir/${id}`);
  }
  /*    async deleteCliente(caja) {
    const clienteEliminado = await fetchWrapper.delete(
      `${ruta}/caja/` + caja.id
    );
    return clienteEliminado;
  }
  async showCliente(caja) {
    const clienteShow = await fetchWrapper.get(`${ruta}/caja/` + caja);
    return clienteShow;
  } */

  async verificarCajaAbierta() { 
    return await fetchWrapper.get(`${ruta}/caja/verificar`);
  }

  async getCaja(id){
    return await fetchWrapper.get(`${ruta}/caja/${id}`);
  }

  async eliminarCaja(id){
    return await fetchWrapper.delete(`${ruta}/caja/${id}`);
  }

  async newMovimientoCaja(movimiento){
    return await fetchWrapper.post(`${ruta}/mov_caja`, movimiento);
  }

  async filtrarCajas(datos) {
    return await fetchWrapper.post(`${ruta}/filtros_caja`, datos);
  }

  async generarPDF(datos) {
    return await fetchWrapper.postPdf(`${ruta}/reporte/caja/imprimir`, datos);
  }

  async exportCajasAll(datos) {
    const exportado = await fetchWrapper.postWithBlob(
      `${ruta}/cajas-export`,
      datos
    );
    return exportado;
  }

  async getSaldoAnterior() {
    return await fetchWrapper.get(`${ruta}/getSaldoAnterior`);
  }

  async openCaja(id) {
    return await fetchWrapper.post(`${ruta}/caja/open/${id}`);
  }

  async imprimirMovimientosPDF(id) {
    return await fetchWrapper.postPdf(`${ruta}/caja/imprimirMovimientos/${id}`);
  }
}
